<template>
  <BaseModal
    name="dominio-modal"
    id="dominio-modal"
    idModal="dominio-modal"
    size="lg"
    title="Crie seu caixa de e-mail"
    @shown="limpar"
  >
    <div>
      <div class="top-dados">
        <p>Selecione o domínio que deseja utilizar</p>
      </div>
      <b-form-group>
        <label class="mb-3">E-mail</label>
        <div class="email">
          <input
            v-model="email"
            class="w-100 input-concat-left"
            type="text"
            name="email"
            id="email"
          />
          <input
            class="input-concat-right"
            disabled
            type="text"
            name="dominio"
            id="dominio"
            :value="
              dominio && dominio.dominio_min
                ? '@' + dominio.dominio_min
                : 'Sem domínio'
            "
          />
        </div>
        <label class="mb-3">Senha</label>
        <input
          v-model="senha"
          class="w-100"
          type="text"
          name="password"
          id="password"
        />
        <p>A senha deve atender aos seguintes requisitos:</p>
        <div v-if="!/[A-Z]/.test(senha)" class="password-validate">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.9375 1.23779L1.9375 13.2378M1.9375 1.23779L13.9375 13.2378"
              stroke="red"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span class="wrong">1 ou mais caracteres maiusculos</span>
        </div>
        <div v-if="/[A-Z]/.test(senha)" class="password-validate">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
          >
            <path
              d="M9 0.523285C4.03745 0.523285 0 4.56074 0 9.52329C0 14.4858 4.03745 18.5233 9 18.5233C13.9625 18.5233 18 14.4858 18 9.52329C18 4.56074 13.9625 0.523285 9 0.523285ZM13.6839 6.50699L7.86851 13.4301C7.80472 13.506 7.72534 13.5674 7.63576 13.6101C7.54617 13.6527 7.44848 13.6756 7.34928 13.6771H7.3376C7.24056 13.6771 7.14461 13.6567 7.05598 13.6172C6.96735 13.5776 6.88802 13.52 6.82313 13.4478L4.33082 10.6786C4.26752 10.6114 4.21828 10.5323 4.186 10.4459C4.15371 10.3595 4.13903 10.2674 4.14282 10.1753C4.1466 10.0831 4.16877 9.99256 4.20803 9.90907C4.24729 9.82557 4.30285 9.75076 4.37143 9.68904C4.44002 9.62732 4.52025 9.57993 4.60741 9.54966C4.69457 9.51939 4.7869 9.50685 4.87898 9.51277C4.97106 9.51869 5.06102 9.54296 5.14359 9.58415C5.22615 9.62534 5.29965 9.68261 5.35976 9.75261L7.31942 11.9299L12.6238 5.61651C12.7428 5.47894 12.9111 5.39373 13.0924 5.37928C13.2737 5.36484 13.4534 5.42232 13.5927 5.5393C13.732 5.65628 13.8196 5.82337 13.8367 6.00445C13.8537 6.18553 13.7989 6.36605 13.6839 6.50699Z"
              fill="var(--greenn)"
            />
          </svg>
          <span class="ok">1 ou mais caracteres maiusculos</span>
        </div>

        <div v-if="!/[a-z]/.test(senha)" class="password-validate">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.9375 1.23779L1.9375 13.2378M1.9375 1.23779L13.9375 13.2378"
              stroke="red"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span class="wrong">1 ou mais caracteres minusculos</span>
        </div>
        <div v-if="/[a-z]/.test(senha)" class="password-validate">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
          >
            <path
              d="M9 0.523285C4.03745 0.523285 0 4.56074 0 9.52329C0 14.4858 4.03745 18.5233 9 18.5233C13.9625 18.5233 18 14.4858 18 9.52329C18 4.56074 13.9625 0.523285 9 0.523285ZM13.6839 6.50699L7.86851 13.4301C7.80472 13.506 7.72534 13.5674 7.63576 13.6101C7.54617 13.6527 7.44848 13.6756 7.34928 13.6771H7.3376C7.24056 13.6771 7.14461 13.6567 7.05598 13.6172C6.96735 13.5776 6.88802 13.52 6.82313 13.4478L4.33082 10.6786C4.26752 10.6114 4.21828 10.5323 4.186 10.4459C4.15371 10.3595 4.13903 10.2674 4.14282 10.1753C4.1466 10.0831 4.16877 9.99256 4.20803 9.90907C4.24729 9.82557 4.30285 9.75076 4.37143 9.68904C4.44002 9.62732 4.52025 9.57993 4.60741 9.54966C4.69457 9.51939 4.7869 9.50685 4.87898 9.51277C4.97106 9.51869 5.06102 9.54296 5.14359 9.58415C5.22615 9.62534 5.29965 9.68261 5.35976 9.75261L7.31942 11.9299L12.6238 5.61651C12.7428 5.47894 12.9111 5.39373 13.0924 5.37928C13.2737 5.36484 13.4534 5.42232 13.5927 5.5393C13.732 5.65628 13.8196 5.82337 13.8367 6.00445C13.8537 6.18553 13.7989 6.36605 13.6839 6.50699Z"
              fill="var(--greenn)"
            />
          </svg>
          <span class="ok">1 ou mais caracteres minusculos</span>
        </div>

        <div v-if="!/[0-9]/.test(senha)" class="password-validate">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.9375 1.23779L1.9375 13.2378M1.9375 1.23779L13.9375 13.2378"
              stroke="red"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span class="wrong">1 ou mais caracteres do tipo númerico</span>
        </div>
        <div v-if="/[0-9]/.test(senha)" class="password-validate">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
          >
            <path
              d="M9 0.523285C4.03745 0.523285 0 4.56074 0 9.52329C0 14.4858 4.03745 18.5233 9 18.5233C13.9625 18.5233 18 14.4858 18 9.52329C18 4.56074 13.9625 0.523285 9 0.523285ZM13.6839 6.50699L7.86851 13.4301C7.80472 13.506 7.72534 13.5674 7.63576 13.6101C7.54617 13.6527 7.44848 13.6756 7.34928 13.6771H7.3376C7.24056 13.6771 7.14461 13.6567 7.05598 13.6172C6.96735 13.5776 6.88802 13.52 6.82313 13.4478L4.33082 10.6786C4.26752 10.6114 4.21828 10.5323 4.186 10.4459C4.15371 10.3595 4.13903 10.2674 4.14282 10.1753C4.1466 10.0831 4.16877 9.99256 4.20803 9.90907C4.24729 9.82557 4.30285 9.75076 4.37143 9.68904C4.44002 9.62732 4.52025 9.57993 4.60741 9.54966C4.69457 9.51939 4.7869 9.50685 4.87898 9.51277C4.97106 9.51869 5.06102 9.54296 5.14359 9.58415C5.22615 9.62534 5.29965 9.68261 5.35976 9.75261L7.31942 11.9299L12.6238 5.61651C12.7428 5.47894 12.9111 5.39373 13.0924 5.37928C13.2737 5.36484 13.4534 5.42232 13.5927 5.5393C13.732 5.65628 13.8196 5.82337 13.8367 6.00445C13.8537 6.18553 13.7989 6.36605 13.6839 6.50699Z"
              fill="var(--greenn)"
            />
          </svg>
          <span class="ok">1 ou mais caracteres do tipo númerico</span>
        </div>

        <div v-if="!/\W|_/.test(senha)" class="password-validate">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.9375 1.23779L1.9375 13.2378M1.9375 1.23779L13.9375 13.2378"
              stroke="red"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span class="wrong">1 ou mais caracteres do tipo simbolo</span>
        </div>
        <div v-if="/\W|_/.test(senha)" class="password-validate">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
          >
            <path
              d="M9 0.523285C4.03745 0.523285 0 4.56074 0 9.52329C0 14.4858 4.03745 18.5233 9 18.5233C13.9625 18.5233 18 14.4858 18 9.52329C18 4.56074 13.9625 0.523285 9 0.523285ZM13.6839 6.50699L7.86851 13.4301C7.80472 13.506 7.72534 13.5674 7.63576 13.6101C7.54617 13.6527 7.44848 13.6756 7.34928 13.6771H7.3376C7.24056 13.6771 7.14461 13.6567 7.05598 13.6172C6.96735 13.5776 6.88802 13.52 6.82313 13.4478L4.33082 10.6786C4.26752 10.6114 4.21828 10.5323 4.186 10.4459C4.15371 10.3595 4.13903 10.2674 4.14282 10.1753C4.1466 10.0831 4.16877 9.99256 4.20803 9.90907C4.24729 9.82557 4.30285 9.75076 4.37143 9.68904C4.44002 9.62732 4.52025 9.57993 4.60741 9.54966C4.69457 9.51939 4.7869 9.50685 4.87898 9.51277C4.97106 9.51869 5.06102 9.54296 5.14359 9.58415C5.22615 9.62534 5.29965 9.68261 5.35976 9.75261L7.31942 11.9299L12.6238 5.61651C12.7428 5.47894 12.9111 5.39373 13.0924 5.37928C13.2737 5.36484 13.4534 5.42232 13.5927 5.5393C13.732 5.65628 13.8196 5.82337 13.8367 6.00445C13.8537 6.18553 13.7989 6.36605 13.6839 6.50699Z"
              fill="var(--greenn)"
            />
          </svg>
          <span class="ok">1 ou mais caracteres do tipo simbolo</span>
        </div>
        <div v-if="!puxarTamanho(senha)" class="password-validate">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.9375 1.23779L1.9375 13.2378M1.9375 1.23779L13.9375 13.2378"
              stroke="red"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span class="wrong">No minímo 8 caracteres</span>
        </div>
        <div v-if="puxarTamanho(senha)" class="password-validate">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
          >
            <path
              d="M9 0.523285C4.03745 0.523285 0 4.56074 0 9.52329C0 14.4858 4.03745 18.5233 9 18.5233C13.9625 18.5233 18 14.4858 18 9.52329C18 4.56074 13.9625 0.523285 9 0.523285ZM13.6839 6.50699L7.86851 13.4301C7.80472 13.506 7.72534 13.5674 7.63576 13.6101C7.54617 13.6527 7.44848 13.6756 7.34928 13.6771H7.3376C7.24056 13.6771 7.14461 13.6567 7.05598 13.6172C6.96735 13.5776 6.88802 13.52 6.82313 13.4478L4.33082 10.6786C4.26752 10.6114 4.21828 10.5323 4.186 10.4459C4.15371 10.3595 4.13903 10.2674 4.14282 10.1753C4.1466 10.0831 4.16877 9.99256 4.20803 9.90907C4.24729 9.82557 4.30285 9.75076 4.37143 9.68904C4.44002 9.62732 4.52025 9.57993 4.60741 9.54966C4.69457 9.51939 4.7869 9.50685 4.87898 9.51277C4.97106 9.51869 5.06102 9.54296 5.14359 9.58415C5.22615 9.62534 5.29965 9.68261 5.35976 9.75261L7.31942 11.9299L12.6238 5.61651C12.7428 5.47894 12.9111 5.39373 13.0924 5.37928C13.2737 5.36484 13.4534 5.42232 13.5927 5.5393C13.732 5.65628 13.8196 5.82337 13.8367 6.00445C13.8537 6.18553 13.7989 6.36605 13.6839 6.50699Z"
              fill="var(--greenn)"
            />
          </svg>
          <span class="ok">No minímo 8 caracteres</span>
        </div>
      </b-form-group>
    </div>
    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Fechar
      </BaseButton>
      <BaseButton variant="black" @click="criarWebmail" :disabled="loading">
        Salvar
      </BaseButton>
    </template>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </BaseModal>
</template>
<script>
import Multiselect from "vue-multiselect";
import WebmailService from "@/services/resources/WebmailService";
const serviceWebmail = WebmailService.build();

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      mensage: null,
      loading: false,
      senha: "",
      email: "",
    };
  },
  props: ["dominio"],
  computed: {
    forms() {
      return this.$store.getters.forms;
    },
  },
  methods: {
    puxarTamanho(variavel) {
      if (variavel.length < 8) {
        return false;
      } else {
        return true;
      }
    },
    limpar() {
      this.email = "";
      this.senha = "";
    },
    criarWebmail() {
      var emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/g;
      if (!this.email.length) {
        this.$grToast.toast("Insira um e-mail", {
          title: "Gestão de sites",
          variant: "danger",
          autoHideDelay: 200,
          appendToast: true,
        });
        return;
      }
      if (!this.email.match(emailRegex)) {
        this.$grToast.toast("Insira um e-mail válido", {
          title: "Gestão de sites",
          variant: "danger",
          autoHideDelay: 200,
          appendToast: true,
        });
        return;
      }
      if (!this.senha.length) {
        this.$grToast.toast("Insira uma senha", {
          title: "Gestão de sites",
          variant: "danger",
          autoHideDelay: 200,
          appendToast: true,
        });
        return;
      }
      if (
        !/[A-Z]/.test(this.senha) ||
        !/[a-z]/.test(this.senha) ||
        !/[0-9]/.test(this.senha) ||
        !/\W|_/.test(this.senha) ||
        this.senha.length < 8
      ) {
        this.$grToast.toast("Siga o padrão de criação de senha", {
          title: "Gestão de sites",
          variant: "danger",
          autoHideDelay: 200,
          appendToast: true,
        });
        return;
      }
      let data = {
        email: this.email,
        password: this.senha,
        domain: this.dominio.dominio_min,
      };
      serviceWebmail
        .create(data)
        .then(() => {
          this.$grToast.toast("Webmail criado com sucesso", {
            title: "Gestão de sites",
            variant: "info",
            autoHideDelay: 200,
            appendToast: true,
          });
          this.$bvModal.hide("dominio-modal");
        })
        .catch((error) => {
          this.$grToast.toast("Erro ao criar Webmail", {
            title: "Gestão de sites",
            variant: "danger",
            autoHideDelay: 200,
            appendToast: true,
          });
        });
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}
.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.top-dados {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .top-dados {
    display: block;
  }
  .top-dados #limpar {
    margin-top: 15px;
  }
}
.form-relative {
  position: relative;
}
.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}
.email {
  display: flex;
  align-items: center;
}
.password-validate {
  display: flex;
  align-items: center;
  gap: 5px;
}
.wrong {
  color: red;
  font-size: 11px;
}
.ok {
  color: var(--greenn);
  font-size: 11px;
}
svg {
  height: 10px;
}
#dominio {
  width: 60%;
}
.input-concat-right {
  border-radius: 0px 5px 5px 0px !important;
}
.input-concat-left {
  border-radius: 5px 0px 0px 5px !important;
}
</style>
