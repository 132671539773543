<template>
    <div>
        <Dominios></Dominios>
    </div>

</template>

<script>
import BaseView from '@/template/BaseView.vue';
import Dominios from '@/components/Configuracoes/Dominios/Dominios.vue';

export default {
  components: {
    BaseView,
    Dominios
  },
  // created() {
  //   if (this.$store.getters.user.user.level == 'seller') {this.$router.push("/dynamicRoute/AccessDenied")};
  // },
}
</script>